import React from "react"

import Heading from "@ui/heading"
import Swiper from "@ui/swiper"
import ImageOne from "@data/images/blog/blog-02-900x678.jpg"
import ImageTwo from "@data/images/blog/blog-03-900x678.jpg"
import ImageThree from "@data/images/blog/blog-04-900x678.jpg"
import ImageFour from "@data/images/blog/blog-05-900x678.jpg"

const SmoothTransitionSlider = ({ sliderSettings }) => {
	const params = {
		slidesPerView: 1,
		spaceBetween: 30,
		arrows:true,
		loop: true,
		pagination: {
		  el: '.swiper-pagination',
		  clickable: true
		},
		loop: true,
    autoplay: true,
    delay: 0,
    speed: 7000,
		navigation: {
		  nextEl: '.swiper-button-next',
		  prevEl: '.swiper-button-prev'
		}
	  }
  return (
    <Swiper {...params}>
          <div className="item">
            <img src={ImageOne} alt="Slider" />
          </div>
          <div className="item">
            <img src={ImageTwo} alt="Slider" />
          </div>
          <div className="item">
            <img src={ImageThree} alt="Slider" />
          </div>
          <div className="item">
            <img src={ImageFour} alt="Slider" />
          </div>
        </Swiper>
  )
}

SmoothTransitionSlider.defaultProps = {
  sliderSettings: {
    slidesPerView: "auto",
    freeMode: true,
    loop: true,
    autoplay: true,
    delay: 0,
    speed: 7000,
    paginationStyle: {
      mt: "50px",
    },
  },
}

export default SmoothTransitionSlider
